"use client";

import { Fragment, useEffect, useMemo, useState } from "react";
import Markdown from "@/components/atoms/Markdown";
import styles from "./styles.module.scss";
import cn from "clsx";
import { GAEvent, GA_EVENTS_PAYLOAD, IProgramPageSessionData } from "@/helpers/gaEvents";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGAPayload } from "@/helpers/getGAPayload";
import { useSearchParams } from "next/navigation";
import { usePlatformAndOS } from "@/helpers/usePlatformAndOS";
import { useDeviceInfo } from "@/helpers/userDeviceType";
import Image from "next/image";
import { StorageService } from "@/helpers/storage.service";
import Link from "next/link";
import { getGlobalStateValue } from "@/context/utils";

export default function DegreeProgramCard({
  Heading,
  url_key,
  ProgramType,
  Title3,
  CourseFee,
  CourseFeeForUS,
  NonDiscountedCourceFeeForUS,
  NonDiscountedCourseFee,
  CourseStripText,
  YearsIcon,
  Image: ImageAttr,
  isSmall = false,
  scroll = false,
  isCertification = false,
  country = "IN",
  isNotClickable = false,
  title
}) {
  
  const searchParams = useSearchParams();
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageReferrer, setPageReferrer] = useState<string>('');
  const { platform = "", version = "" } = usePlatformAndOS();
  const deviceType = useDeviceInfo();

  const ParentComponent = useMemo(
    () =>
      isNotClickable
        ? { component: Fragment, params: null }
        : {
            component: Link,
            params: { href: url_key ? `/${url_key.replace(/^\//, '')}` : "#", scroll, target: "_self" },
          },
    [isNotClickable, scroll, url_key],
  );

  useEffect(() => {
		setPageReferrer(document.referrer);
		setPageTitle(document.title);
		const handleTitleChange = () => {
		  setPageTitle(document.title);
		};
		document.addEventListener('titlechange', handleTitleChange); 
		return () => {
		  document.removeEventListener('titlechange', handleTitleChange);
		};
	}, []);

  const isMbaCard = Heading
    ? Heading.toLowerCase().includes("master of business administration")
    : false;
  const { PROGRAM_CARD_MBA } = GAEvent;
  const handleGaClick = () => {
    if(isMbaCard){
      const gaData = GA_EVENTS_PAYLOAD.get(PROGRAM_CARD_MBA);
      const gaOtherPayloadInfo = {
        platform,
        deviceType,
        version,
        pageTitle,
        pageReferrer,
        pageUrl : typeof window !== 'undefined' ? window.location.href : '',
      }
      const sessionData:IProgramPageSessionData = {
        isNavbarClick : false,
        isMbaPage : true
      }
      StorageService.set("programDetailsGAInfo", sessionData)
      const gaPayload = getGAPayload(gaData,searchParams,gaOtherPayloadInfo);
      pushToDataLayer(gaPayload);
    }else{
      const sessionData:IProgramPageSessionData = {
        isNavbarClick : false,
        isMbaPage : false
      }
      StorageService.set("programDetailsGAInfo", sessionData)
    }
    pushToDataLayer({
        'event':'card_interaction',
        'cta_text': Heading,
        'section_name': title || "NA",
        'page_type': getGlobalStateValue("pageType")
    });
  }

  return (
    <ParentComponent.component
      {...ParentComponent?.params}
      {...(!isNotClickable && { onClick: handleGaClick })}
    >
      <div
        className={cn(styles.card, {
          [styles.smallCard]: isSmall,
          [styles.biggerGradient]: isCertification,
        })}
      >
        <Image
          src={ImageAttr?.source?.data?.[0]?.attributes?.url}
          alt={ImageAttr?.source?.data?.[0]?.attributes?.alternativeText || ""}
          className={cn("w-full object-cover", styles.img, {
            [styles.bigImg]: isSmall,
          })}
          width={300}
          height={280}
        />
        {!!CourseStripText && !isCertification && (
          <p
            className={cn(styles.strip, {
              [styles.smallStrip]: isSmall,
            })}
          >
            {CourseStripText}
          </p>
        )}
        <div className={styles.textContainer}>
          {!CourseStripText && !isCertification && (
            <Markdown className={styles.programType}>
              {ProgramType?.split("_")?.join(" ")}
            </Markdown>
          )}
          <div className={styles.nameContainer}>
            <Markdown
              className={cn(styles.name, {
                [styles.certificationName]: isCertification,
              })}
            >
              {Heading}
            </Markdown>
            <div className={styles.yearContainer}>
              <Image
                src={YearsIcon?.source?.data?.[0]?.attributes?.url}
                alt={
                  YearsIcon?.source?.data?.[0]?.attributes?.alternativeText ||
                  "Years Icon"
                }
                loading="lazy"
                width={28}
                height={32}
              />
              <span
                className={cn({
                  ["!tracking-normal"]: isCertification,
                })}
              >
                {YearsIcon?.title ? YearsIcon?.title : "N/A"}
              </span>
            </div>
          </div>
          {!!Title3 && <p className={styles.desc}>{Title3}</p>}
          {!!CourseFee && (
            <p
              className={cn(styles.fees, {
                [styles.certificationFees]: isCertification,
              })}
            >
              <span>Course Fee: </span>
              {isCertification ? (
                <span className={styles.strikeThrough}>
                  {country === "IN"
                    ? NonDiscountedCourseFee
                    : NonDiscountedCourceFeeForUS || 0}
                </span>
              ) : null}
              <span>
                {country === "IN" ? CourseFee : CourseFeeForUS || 0}/-
              </span>
            </p>
          )}
        </div>
      </div>
    </ParentComponent.component>
  )
}
